//
// Post Feed Flavor
// --------------------------------------------------

.post-feed .panel-body {
  padding-bottom: 0px;
}

.post-feed .post-body {
  position: relative;
  padding-top: 0px;
  padding-bottom: @line-height-computed;
  max-height: 300px;
  overflow-y: hidden;

  &:after {
    box-shadow: 0px 0px 16px 16px #ffffff;
    display: block;

    position: absolute;
    bottom: 0px;

    height: 0px;
    width: 100%;

    content: "-";
    color: transparent;
  }
}

//
// Polls Flavor
// --------------------------------------------------

.panel-poll {
  border: 1px solid @panel-form-border;
}

// Poll vote form
.panel-poll .poll-select-choices {
  margin-top: @line-height-computed / -2;
}

// Poll results styles
.panel-poll .poll-details {
  margin-bottom: @line-height-computed;

  color: @gray-light;
  font-size: @font-size-small;
}

.panel-poll .progress {
  margin-top: @line-height-computed / 3;
  margin-bottom: @line-height-computed / 4;
}

.panel-poll .poll-chart {
  color: @gray-light;
  font-size: @font-size-small;
}

.panel-poll .poll-options {
  margin-top: @line-height-computed;
}

//
// Search Dropdown
// --------------------------------------------------

.dropdown-search-results {
  border-radius: 0px 0px @border-radius-base @border-radius-base;
}

.dropdown-search-message {
  border-top: 1px solid @gray-lighter;
  padding: @padding-large-vertical @padding-large-horizontal;

  color: @text-muted;
}

.dropdown-search-loader {
  border-top: 1px solid @gray-lighter;
  padding: @padding-large-vertical @padding-large-horizontal;
}

.dropdown-search-header {
  border-top: 1px solid @gray-lighter;
  padding: 8px 20px;

  color: @text-muted;
  font-weight: bold;
}

// result
.dropdown-search-thread,
.dropdown-search-user {
  small {
    color: @text-muted;
  }
}

.dropdown-search-thread {
  .dropdown-search-post-content {
    overflow: hidden;
    white-space: normal;
    max-height: 47px;
  }

  .dropdown-search-post-footer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// footer
.dropdown-search-footer {
  padding-bottom: @padding-small-vertical;

  &:last-child {
    padding-bottom: 0px;
  }
}

.dropdown-menu > li.dropdown-search-footer > a {
  font-size: small;
}

//
// Search Dropdown
// --------------------------------------------------

// Default size and position for search dropdown
.navbar-misago .dropdown-menu.dropdown-search-results {
  margin: 0px;
  padding-top: 0px;
  width: 400px;
  left: auto;
  right: 0px;
}

// Search input
.dropdown-search-results .form-group {
  margin: 0px;
  padding: @padding-base-horizontal;
}

// Message
li.dropdown-search-message {
  text-align: center;
}

// Thread result
.dropdown-search-thread {
  width: 100%;

  h5 {
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  small {
    display: block;
    margin: 0px;
    margin-top: 4px;
  }
}

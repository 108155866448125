//
// Markup Flavor
// --------------------------------------------------

// Round images
.misago-markup img {
  border-radius: @border-radius-base;
}

// Spoiler block
.misago-markup .spoiler-body {
  background: transparent;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  color: @text-color;
}

.misago-markup .spoiler-block.revealed .spoiler-body {
  -webkit-filter: none;
  -moz-filter: none;
  -o-filter: none;
  -ms-filter: none;
  filter: none;
}

.misago-markup .spoiler-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: fadeout(@post-bg, 90%);
}

.misago-markup .spoiler-reveal {
  .btn-default();
}

.misago-markup .spoiler-reveal,
.misago-markup .spoiler-reveal:active,
.misago-markup .spoiler-reveal:hover {
  border-radius: 4px;
  box-shadow: 0px 0px 0px 4px @post-bg;
  padding: @padding-base-vertical @padding-base-horizontal;
}

//
// Panels Flavor
// --------------------------------------------------

.panel {
  border: none;
  border-radius: @border-radius-base;

  .panel-heading,
  .panel-footer {
    background: @panel-bg;
  }

  .panel-title {
    color: @panel-title-color;
  }

  .form-group + .form-group {
    margin-top: @line-height-computed;
  }

  .panel-body > .form-group:first-child {
    margin-top: @line-height-computed / 2;
  }

  fieldset {
    margin-top: @line-height-computed;

    &:first-child {
      margin-top: 0px;
    }

    legend {
      border: none;

      color: @panel-fieldset-color;
      font-weight: 300;
    }
  }
}

// use default message icon color for panel messages
.panel-message-body .message-icon {
  color: @message-icon-color;
}

//
// User Options Flavor
// --------------------------------------------------

.page-options .message-line {
  color: @gray-light;

  a,
  a:link,
  a:visited {
    color: @gray-light;
  }

  a:hover,
  a:focus,
  a:focus:active,
  a:active {
    color: @text-color;
  }
}

//
// Flavor Navbar
// --------------------------------------------------

.navbar-misago {
  border-bottom: none;
  box-shadow: 0px 0px 3px @navbar-default-border;
}

// Round up user's avatar
.navbar-misago .user-avatar {
  background: #fff;
  border-radius: @border-radius-small;
}

// Round up brand's icon
.navbar-misago .brand-link img {
  border-radius: @border-radius-small;
}

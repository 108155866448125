//
// Message Pages Flavor
// --------------------------------------------------

.page-error .message-icon {
  color: @error-icon-color;
}

.page-message .message-icon {
  color: @message-icon-color;
}

.page-message-info .message-icon {
  color: @message-info-icon-color;
}

.page-message-success .message-icon {
  color: @message-success-icon-color;
}

// Quote control is small tooltip with "quote" button
// It's displayed next to selected text in posts

.quote-control-arrow {
  width: 0;
  height: 0;
  border-left: @quote-control-padding solid transparent;
  border-right: @quote-control-padding solid transparent;
  border-bottom: @quote-control-padding solid @quote-control-bg;

  position: relative;
  left: @quote-control-radius;
}

.quote-control-inner {
  padding: @quote-control-padding;

  background: @quote-control-bg;
  border-radius: @quote-control-radius;
}

.quote-control-btn {
  background: @quote-control-btn-bg;
  border-color: @quote-control-btn-bg;
  color: @quote-control-btn-color;
}

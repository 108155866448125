//
// Social Buttons Flavor
// --------------------------------------------------

// Those buttons simply add brand color over the .btn-default
.misago-social-btn(@color) {
  &,
  &:disabled,
  &:disabled:hover {
    color: @color;
    font-weight: bold;
  }
}

.btn-social-battlenet,
.btn-social-battlenet-oauth2 {
  .misago-social-btn(#0e86ca);
}

.btn-social-bungie {
  .misago-social-btn(#0096db);
}

.btn-social-facebook,
.btn-social-facebook-app {
  .misago-social-btn(#3b5998);
}

.btn-social-github,
.btn-social-github-team,
.btn-social-github-enterprise,
.btn-social-github-enterprise-org,
.btn-social-github-enterprise-team {
  .misago-social-btn(#000000);
}

.btn-social-gitlab {
  .misago-social-btn(#fc6d26);
}

.btn-social-google,
.btn-social-google-oauth,
.btn-social-google-oauth2,
.btn-social-google-plus,
.btn-social-google-openidconnect {
  .misago-social-btn(#dd4b39);
}

.btn-social-linkedin {
  .misago-social-btn(#0077b5);
}

.btn-social-steam {
  .misago-social-btn(#5c7e10);
}

.btn-social-twitter {
  .misago-social-btn(#1da1f2);
}

.form-social-auth {
  .row {
    margin-top: @padding-base-vertical * -1;
    margin-bottom: @padding-base-vertical * -1;
  }

  .btn {
    margin: @padding-base-vertical 0px;
  }
}

//
// User Card Flavor
// --------------------------------------------------

// Background color
.user-card {
  background: @user-card-bg;
}

// Round avatar corners
.user-card-small-avatar,
.user-card-avatar {
  img {
    border-radius: @border-radius-base;
  }
}

// Sizing and spacing on large avatar
.user-card-avatar {
  margin: @line-height-computed 0px;

  img {
    width: 150px;
    height: 150px;
  }
}

// Upsize the user name
.user-card-username {
  a,
  a:link,
  a:visited,
  a:hover,
  a:focus,
  a:active {
    color: @text-color;
    font-size: @font-size-large;
    font-weight: bold;
  }
}

// User title
.user-card-title {
  span,
  a,
  a:link,
  a:visited {
    color: @gray;
  }

  a:focus,
  a:hover,
  a:active {
    color: @text-color;
  }
}

// User stats
.user-card-stats {
  @media screen and (min-width: @screen-sm-min) {
    margin-top: @line-height-computed;
  }

  ul {
    margin: 0px;
  }

  li {
    display: inline-block;
    margin-right: @padding-base-horizontal;

    color: @gray-light;
    font-size: @font-size-small;

    @media screen and (min-width: @screen-sm-min) {
      margin: 0px @padding-base-horizontal / 2;
    }
  }

  li.user-stat-empty {
    display: none;
  }
}

// Divide stats in two on larger screen
@media screen and (min-width: @screen-sm-min) {
  li.user-stat-divider {
    display: block;
    margin: 0px;
  }
}

@media screen and (max-width: @screen-xs-max) {
  li.user-stat-divider {
    display: none;
  }
}

// Make stats 60px min height on tablets and up
@media screen and (min-width: @screen-sm-min) {
  .user-card-stats {
    min-height: 60px;
  }
}

// Reduce gutter on small grid
@media screen and (max-width: @screen-xs-max) {
  .user-card-left {
    padding-right: 0px;
  }
}

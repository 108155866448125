.threads-replies {
  display: flex;
  align-items: center;

  color: @thread-replies-color;
  font-size: @thread-replies-size;
  font-weight: bold;

  .material-icon {
    margin-right: @grid-gutter-width / 4;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

//
// Posts List
// --------------------------------------------------

// Remove paddings and margins from items list
.posts-list {
  margin: 0px;
  padding: 0px;
  clear: both;

  li {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
}

//
// Flavor Shadows
// --------------------------------------------------

.shadow-2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, @shadow-key-penumbra-opacity),
    0 3px 1px -2px rgba(0, 0, 0, @shadow-key-umbra-opacity),
    0 1px 5px 0 rgba(0, 0, 0, @shadow-ambient-shadow-opacity);
}

.shadow-3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, @shadow-key-penumbra-opacity),
    0 3px 3px -2px rgba(0, 0, 0, @shadow-key-umbra-opacity),
    0 1px 8px 0 rgba(0, 0, 0, @shadow-ambient-shadow-opacity);
}

.shadow-4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, @shadow-key-penumbra-opacity),
    0 1px 10px 0 rgba(0, 0, 0, @shadow-ambient-shadow-opacity),
    0 2px 4px -1px rgba(0, 0, 0, @shadow-key-umbra-opacity);
}

.shadow-6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, @shadow-key-penumbra-opacity),
    0 1px 18px 0 rgba(0, 0, 0, @shadow-ambient-shadow-opacity),
    0 3px 5px -1px rgba(0, 0, 0, @shadow-key-umbra-opacity);
}

.shadow-8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, @shadow-key-penumbra-opacity),
    0 3px 14px 2px rgba(0, 0, 0, @shadow-ambient-shadow-opacity),
    0 5px 5px -3px rgba(0, 0, 0, @shadow-key-umbra-opacity);
}

.shadow-16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, @shadow-key-penumbra-opacity),
    0 6px 30px 5px rgba(0, 0, 0, @shadow-ambient-shadow-opacity),
    0 8px 10px -5px rgba(0, 0, 0, @shadow-key-umbra-opacity);
}

.shadow-24dp {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, @shadow-key-penumbra-opacity),
    0 11px 15px -7px rgba(0, 0, 0, @shadow-ambient-shadow-opacity),
    0 24px 38px 3px rgba(0, 0, 0, @shadow-key-umbra-opacity);
}

//
// Forms
// --------------------------------------------------

// Material feedback icon
.has-feedback .material-icon.form-control-feedback {
  top: @padding-base-vertical;
  right: @padding-base-horizontal * 2;

  font-size: @line-height-base;
  line-height: @line-height-base;
}

// Well done
.well.well-form.well-done {
  font-size: @font-size-large;
  text-align: center;

  .message-icon {
    margin-bottom: @line-height-computed / 2;

    font-size: @font-size-large * 5;
    line-height: @font-size-large * 5;
  }

  .message-body {
    margin-bottom: @line-height-computed;
  }
}

// Noscript well
.well.well-form.well-noscript {
  font-size: @font-size-large;
  text-align: center;

  .message-icon {
    margin-bottom: @line-height-computed / 2;

    font-size: @font-size-large * 5;
    line-height: @font-size-large * 5;
  }
}

//
// Posting Form Flavor
// --------------------------------------------------

#posting-placeholder {
  background-color: #fafbfc;
}

.posting-message {
  .material-icon {
    color: @message-info-icon-color;
  }

  .btn {
    margin-top: @line-height-computed;
  }
}

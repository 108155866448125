//
// Editor
// --------------------------------------------------

@editor-border: darken(@gray-lighter, 15%);

.editor-border {
  background-color: #fff;
  border: 1px solid @editor-border;
  border-radius: @border-radius-base;
}

.editor-border .form-control {
  // remove border and resizing
  border: none;
  resize: none;

  // remove shadow from control
  &,
  &:focus,
  &:active {
    .box-shadow(none);
  }
}

// Footer styles
.editor-footer {
  border-top: 1px solid @editor-border;
  padding: @padding-base-vertical @padding-base-horizontal;
  overflow: auto;

  .pull-left {
    margin-right: @padding-base-horizontal;
  }

  .pull-right {
    margin-left: @padding-base-horizontal;
  }

  .btn-icon .material-icon {
    margin-bottom: -2px;
  }

  // center buttons on small devices
  @media screen and (max-width: @screen-sm-max) {
    .buttons-list {
      float: none !important;

      margin: 0px;
      margin-bottom: @line-height-computed / 2;

      .btn {
        display: inline-block;
        float: none !important;

        margin: @line-height-computed / 3;
      }
    }

    .btn-protect {
      .btn-text {
        margin-left: @padding-small-horizontal;
      }

      .material-icon {
        position: relative;
        bottom: 2px;

        width: 14px;
        height: 14px;

        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

@media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .buttons-list .btn {
    &:first-child {
      margin-left: 0px;
    }
  }
}

// make lock button full width
@media screen and (max-width: @screen-xs-max) {
  .buttons-list {
    text-align: center;

    .btn-protect {
      display: block;
      float: none !important;

      width: 100%;

      margin: 0px;
      margin-top: @line-height-computed / 2;
    }
  }
}

// Attachments list
.editor-attachments-list {
  margin: 0px;
  padding: 0px;

  li {
    margin: 0px;
  }
}

.editor-attachment-complete {
  border-top: 1px solid @editor-border;
  padding: @padding-base-vertical 0px;
  padding-right: @padding-base-horizontal;

  .editor-attachment-image {
    float: left;
    width: 50px;

    a {
      background-size: cover;
      background-position: center;
      border-radius: @border-radius-small;
      display: block;

      margin: 0px auto;
      width: 36px;
      height: 36px;
    }
  }

  .editor-attachment-icon {
    float: left;
    width: 50px;

    text-align: center;

    .material-icon {
      position: relative;
      top: 2px;

      height: 28px;
      width: 28px;

      font-size: 28px;
      line-height: 28px;
    }
  }

  .editor-attachment-details {
    margin-left: 50px;

    h4,
    p {
      margin: 0px;
      padding: 0px;

      font-size: @font-size-base;
    }

    p {
      margin-top: @padding-base-vertical / 2;

      color: @gray-light;
      font-size: @font-size-small;
    }

    abbr {
      white-space: nowrap;
    }
  }
}

.editor-attachment-actions {
  @media screen and (min-width: @screen-sm-min) {
    padding-top: 3px;
  }

  @media screen and (max-width: @screen-xs-max) {
    padding-left: @padding-base-horizontal;
    padding-right: @padding-base-horizontal;
  }
}

.editor-attachment-error {
  border-top: 1px solid @editor-border;
  padding: @padding-base-vertical 0px;
  padding-right: @padding-base-horizontal;
}

.editor-attachment-error-icon {
  float: left;
  width: 50px;

  text-align: center;

  .material-icon {
    position: relative;
    top: 2px;

    height: 28px;
    width: 28px;

    font-size: 28px;
    line-height: 28px;
  }
}

.editor-attachment-error-message {
  margin-left: 50px;
  padding: @padding-base-vertical 0px;
  position: relative;

  h4,
  p {
    margin: 0px;
    padding: 0px;

    font-size: @font-size-base;
  }

  p {
    margin-top: @padding-base-vertical / 2;
    font-size: @font-size-small;
  }

  .btn {
    position: absolute;
    top: @padding-base-vertical + 3px;
    right: @padding-base-horizontal;

    @media screen and (max-width: @screen-xs-max) {
      display: block;

      margin-top: @line-height-computed / 2;

      position: static;
    }
  }
}

.editor-attachment-progress-bar {
  background: @editor-border;
  overflow: auto;
}

.editor-attachment-progress {
  background: @brand-primary;
  float: left;
  height: 1px;
}

.editor-attachment-upload-message {
  margin: 0px;
  padding: @padding-base-vertical @padding-base-horizontal;
}

// Hide file input outside of viewport
#editor-upload-field {
  position: absolute;
  left: -1000px;
  top: -1000px;
}

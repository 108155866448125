//
// Thread Participants
// --------------------------------------------------

.participant-card .btn-user,
.participant-card .dropdown.open .btn-user {
  margin-bottom: @line-height-computed;

  &,
  &:hover,
  &:focus,
  &:focus:active {
    padding: 0px;

    overflow: hidden;

    text-align: left;
  }

  img {
    background-color: #fff;
    width: 34px;
    height: 34px;

    margin-right: 8px;
  }
}

// Panel copy
.panel-participants p {
  margin: 0px;
  margin-top: @padding-base-vertical + 1px;
}

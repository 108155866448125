//
// Buttons
// --------------------------------------------------

// Button loading style
.btn.btn-loading {
  &,
  &:link,
  &:active,
  &:visited,
  &:hover,
  &:focus {
    // make text transparent, but still take place in button
    color: transparent;

    // position loader over the text
    .loader {
      height: 20px;
      margin-top: -20px;

      & > div {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.btn-default .loader > div {
    border-top-color: @btn-default-color;
    border-bottom-color: @btn-default-color;
  }

  &.btn-primary .loader > div {
    border-top-color: @btn-primary-color;
    border-bottom-color: @btn-primary-color;
  }

  &.btn-success .loader > div {
    border-top-color: @btn-success-color;
    border-bottom-color: @btn-success-color;
  }

  &.btn-info .loader > div {
    border-top-color: @btn-info-color;
    border-bottom-color: @btn-info-color;
  }

  &.btn-warning .loader > div {
    border-top-color: @btn-warning-color;
    border-bottom-color: @btn-warning-color;
  }

  &.btn-danger .loader > div {
    border-top-color: @btn-danger-color;
    border-bottom-color: @btn-danger-color;
  }
}

// Icons in buttons
.btn .material-icon {
  margin-right: 3px;
  position: relative;
  bottom: 1px;
}

// Btn with larger icon
.btn-icon .material-icon {
  margin: -1px -4px;

  width: 20px;
  height: 20px;

  font-size: 20px;
  line-height: 20px;
}

.btn-icon .btn-text {
  margin-left: @padding-small-horizontal;
}

.btn-icon .btn-text-left {
  margin-right: @padding-small-horizontal;
}

// Block btn icon
.btn-block.btn-icon {
  padding-left: 0px;
  padding-right: 0px;
}

.btn-icons-family {
  .material-icon + .material-icon {
    margin-left: @padding-small-horizontal;
  }
}

//
// Alerts
// --------------------------------------------------

.alerts-snackbar {
  position: fixed;
  top: -100%;
  width: 100%;
  z-index: @zindex-modal + 10;

  text-align: center;
  font-size: @font-size-large;

  transition: top 300ms ease;

  pointer-events: none;

  &.in {
    top: 0px;
    transition: top 200ms ease;
  }

  p {
    display: inline-block;
    border-radius: 0px 0px @border-radius-base @border-radius-base;
    margin: 0px;

    pointer-events: all;
  }
}

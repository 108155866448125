// Category label is category on threads list
.category-label {
  border-radius: 0.25em;
  padding: 0.2em 0.6em 0.3em;
}

.category-label-color,
.category-label-color:link,
.category-label-color:focus,
.category-label-color:active,
.category-label-color:visited,
.category-label-color:hover {
  color: @category-label-color;
}

.category-label-no-color,
.category-label-no-color:link,
.category-label-no-color:focus,
.category-label-no-color:active,
.category-label-no-color:visited,
.category-label-no-color:hover {
  background-color: @category-label-default-bg;
  color: @category-label-default-color;
}

//
// Misago User Status
// --------------------------------------------------

// Colors
.user-status {
  &.user-banned .status-icon {
    color: @user-status-banned;
  }

  &.user-online .status-icon {
    color: @user-status-online;
  }

  &.user-offline .status-icon {
    color: @user-status-offline;
  }
}

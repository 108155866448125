//
// Navs Flavor
// --------------------------------------------------

.nav-side {
  .box-shadow(none);
}

.nav-side > .list-group-item {
  border-left: none;
  border-right: none;
  margin-bottom: 1px;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
  }

  &,
  &:link,
  &:visited {
    background: @side-nav-bg;
    border: none;

    color: @side-nav-color;
  }

  &:hover,
  &:focus,
  &:active {
    background: @side-nav-hover-bg;

    color: @side-nav-hover-color;
  }

  &.active {
    &,
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      background: @side-nav-active-bg;

      color: @side-nav-active-color;
      font-weight: bold;

      .badge {
        background: @side-nav-bg;

        color: @side-nav-color;
      }
    }
  }
}

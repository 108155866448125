//
// Profile Details
// --------------------------------------------------

.panel-profile-details-group.panel {
  border: 1px solid @panel-form-border;
}

.panel-profile-details-group.panel .form-group {
  margin: 0px;
}

.panel-profile-details-group .form-control-static {
  p:last-child {
    margin-bottom: 0px;
  }
}

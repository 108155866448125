//
// Forum Footer
// --------------------------------------------------

// Superbasic spacing and layout for easy overriding
.misago-footer {
  margin-top: @line-height-computed * 1.5;
  margin-bottom: @line-height-computed * 2.5;

  .footer-content {
    border-top: 1px solid @page-header-border-color;
    padding-top: @line-height-computed * 1.5;
  }
}

// Position "enable JS!" message's icon
.misago-footer .noscript-message .material-icon {
  position: relative;
  bottom: 1px;

  font-size: @font-size-large;
}
